import { closeModal, openModal } from '../Modal';

import { RouteLeaveConfirmationModal } from './RouteLeaveConfirmationModal';
import styles from './styles.module.scss';

export const openRouteLeaveConfirmationModal = (onConfirm: () => void): void => {
	openModal({
		config: {
			customClass: styles.RouteLeaveConfirmationModal
		},
		ContentComponent: (
			<RouteLeaveConfirmationModal
				onLeave={() => {
					onConfirm();
					closeModal();
				}}
				onStay={() => closeModal()}
			/>
		)
	});
};
