import { ConfirmationModalContent } from '../ConfirmationModalContent';

import {
	ROUTE_LEAVE_CONFIRMATION_MODAL_DESCRIPTION,
	ROUTE_LEAVE_CONFIRMATION_MODAL_LEAVE_BUTTON_LABEL,
	ROUTE_LEAVE_CONFIRMATION_MODAL_STAY_BUTTON_LABEL,
	ROUTE_LEAVE_CONFIRMATION_MODAL_TITLE
} from './config';

import styles from './styles.module.scss';

export type RouteLeaveConfirmationModalProps = {
	onLeave: () => void;
	onStay: () => void;
};

export const RouteLeaveConfirmationModal = ({
	onLeave,
	onStay
}: RouteLeaveConfirmationModalProps) => {
	return (
		<ConfirmationModalContent
			onNegativeReply={onLeave}
			negativeActionLabel={ROUTE_LEAVE_CONFIRMATION_MODAL_LEAVE_BUTTON_LABEL}
			onPositiveReply={onStay}
			positiveActionLabel={ROUTE_LEAVE_CONFIRMATION_MODAL_STAY_BUTTON_LABEL}>
			<div className={styles.RouteLeaveConfirmationModalContent}>
				<div className={styles.Title}>{ROUTE_LEAVE_CONFIRMATION_MODAL_TITLE}</div>
				<div className={styles.Description}>
					{ROUTE_LEAVE_CONFIRMATION_MODAL_DESCRIPTION}
				</div>
			</div>
		</ConfirmationModalContent>
	);
};
