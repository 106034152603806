'use client';

import {
	RegisterKelvinModels,
	RouterBlockerContextProvider,
	SessionProvider
} from '@kelvininc/nextjs-utils/client';
import { openRouteLeaveConfirmationModal } from '@kelvininc/shared-ui';
import { Session } from 'next-auth';
import { FC, PropsWithChildren } from 'react';
import { RecoilRoot } from 'recoil';

import { JotaiProvider } from './Jotai';
import { NextLinkProvider } from './NextLinkProvider';

type AppProvidersProps = {
	basePath?: string;
	session: Session | null;
};

export const AppProviders: FC<PropsWithChildren<AppProvidersProps>> = ({
	session,
	basePath,
	children
}) => {
	return (
		<RouterBlockerContextProvider requestConfirmation={openRouteLeaveConfirmationModal}>
			<SessionProvider basePath={basePath} session={session}>
				<NextLinkProvider>
					<RegisterKelvinModels>
						<JotaiProvider>
							<RecoilRoot>{children}</RecoilRoot>
						</JotaiProvider>
					</RegisterKelvinModels>
				</NextLinkProvider>
			</SessionProvider>
		</RouterBlockerContextProvider>
	);
};
