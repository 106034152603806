import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/_core-ui_kelvininc_core-ui_1.7.0/apps/kelvin-core-ui/src/assets/styles/app.scss");
;
import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/home/jenkins/agent/workspace/_core-ui_kelvininc_core-ui_1.7.0/apps/kelvin-core-ui/src/providers/AppProviders.tsx");
;
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/_core-ui_kelvininc_core-ui_1.7.0/apps/kelvin-core-ui/src/ui-components-transformer.ts");
;
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/_core-ui_kelvininc_core-ui_1.7.0/apps/kelvin-core-ui/src/utils/logger.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jenkins/agent/workspace/_core-ui_kelvininc_core-ui_1.7.0/node_modules/.pnpm/next-superjson-plugin@0.6.3_next@14.2.11_superjson@2.2.1/node_modules/next-superjson-plugin/dist/client.js");
;
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/_core-ui_kelvininc_core-ui_1.7.0/packages/nextjs-utils/src/client/index.ts");
