import { EActionButtonType, EIconName, KvActionButtonText } from '@kelvininc/react-ui-components';
import { PropsWithChildren } from 'react';

import styles from './styles.module.scss';

export type ConfirmationModalContentProps = {
	positiveActionLabel?: string;
	positiveActionType?: EActionButtonType;
	positiveActionIcon?: EIconName;
	onPositiveReply: () => void;
	negativeActionLabel?: string;
	negativeActionType?: EActionButtonType;
	negativeActionIcon?: EIconName;
	onNegativeReply: () => void;
};

export const ConfirmationModalContent = ({
	positiveActionLabel = 'Confirm',
	positiveActionType = EActionButtonType.Primary,
	positiveActionIcon,
	onPositiveReply,
	negativeActionLabel = 'Cancel',
	negativeActionType = EActionButtonType.Tertiary,
	negativeActionIcon,
	onNegativeReply,
	children
}: PropsWithChildren<ConfirmationModalContentProps>) => {
	return (
		<div className={styles.ConfirmationModalContent}>
			<div className={styles.Content}>{children}</div>
			<div className={styles.Footer} data-test-id="e2e-modal-actions">
				<KvActionButtonText
					text={negativeActionLabel}
					icon={negativeActionIcon}
					type={negativeActionType}
					onClickButton={onNegativeReply}
				/>
				<KvActionButtonText
					text={positiveActionLabel}
					icon={positiveActionIcon}
					type={positiveActionType}
					onClickButton={onPositiveReply}
				/>
			</div>
		</div>
	);
};
