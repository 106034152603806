import { IModalAlert } from '../../types';
import { modalController$ } from '../config';
import { EModalEvent, ICloseModalConfig, IModalConfig, IOpenModalConfig } from '../types';

export const openModal = (payload: IOpenModalConfig) =>
	modalController$.next({ type: EModalEvent.Open, payload });

export const closeModal = (payload?: ICloseModalConfig) =>
	modalController$.next({ type: EModalEvent.Close, payload });

export const openAlertInModal = (payload: IModalAlert) =>
	modalController$.next({ type: EModalEvent.Alert, payload });

export const closeAlertInModal = () => modalController$.next({ type: EModalEvent.Alert });

export const setModalOverlay = (showOverlay: boolean) => {
	modalController$.next({
		type: EModalEvent.Config,
		payload: {
			showOverlay
		}
	});
};

export const setBlockAlertInModal = (blockAlert: boolean) => {
	modalController$.next({
		type: EModalEvent.Config,
		payload: {
			blockAlert
		}
	});
};

export const setModalConfig = (config: Partial<IModalConfig>) => {
	modalController$.next({
		type: EModalEvent.Config,
		payload: {
			...config
		}
	});
};
