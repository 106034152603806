'use client';

import { isString } from '@kelvininc/tsutils';

const BLACKLISTED_WARNINGS: string[] = [
	/**
	 * This is a known issue on ag-grid and it's already fixed on React 18.
	 * https://github.com/facebook/react/issues/21035#issuecomment-913818467
	 */
	'AG Grid: headerCheckboxSelection is not supported for Server Side Row Model'
];

const warnFallback = console.warn;
console.warn = (message?: unknown, ...optionalParams: unknown[]) => {
	if (isString(message) && BLACKLISTED_WARNINGS.some((errors) => message.startsWith(errors))) {
		return;
	}

	return warnFallback(message, ...optionalParams);
};

const BLACKLISTED_ERRORS: string[] = [
	/**
	 * This is a known issue on ag-grid and it's already fixed on React 18.
	 * https://github.com/facebook/react/issues/21035#issuecomment-913818467
	 */
	'Warning: Invalid aria prop %s on <%s> tag.',
	/**
	 * This is a known issue on ag-grid that has not yet been fixed.
	 * https://github.com/rjsf-team/react-jsonschema-form/issues/1794
	 */
	'Warning: Using UNSAFE_componentWillReceiveProps in strict mode is not recommended and may indicate bugs in your code.',
	/**
	 * Normally this error is caused by a browser extension
	 * https://github.com/vercel/next.js/discussions/22388
	 */
	'Warning: Extra attributes from the server:'
];

const errorFallback = console.error;
console.error = (message?: unknown, ...optionalParams: unknown[]) => {
	if (isString(message) && BLACKLISTED_ERRORS.some((errors) => message.startsWith(errors))) {
		return;
	}

	return errorFallback(message, ...optionalParams);
};
