import { ClassNamesProp } from '@kelvininc/types';
import { ReactNode } from 'react';

import { IModalAlert } from '../types';

export enum EModalEvent {
	Open = 'open',
	Close = 'close',
	Config = 'config',
	Alert = 'alert'
}

export interface IModalConfig {
	title?: string;
	blockAlert?: boolean;
	showOverlay?: boolean;
	showCloseButton?: boolean;
	customClass?: ClassNamesProp;
	onClickClose?: () => void | boolean;
}

export interface IOpenModalConfig {
	config?: IModalConfig;
	ContentComponent: ReactNode;
}

export interface ICloseModalConfig {
	force?: boolean;
}

export interface IModalAlertEventPayload {
	config?: IModalConfig;
	alert?: IModalAlert;
	ContentComponent?: ReactNode;
}

export type OpenModalEventPayload = {
	type: EModalEvent.Open;
	payload: IOpenModalConfig;
};

export type CloseModalEventPayload = {
	type: EModalEvent.Close;
	payload?: ICloseModalConfig;
};

export type ModalAlertConfigEventPayload = {
	type: EModalEvent.Alert;
	payload?: IModalAlert;
};

export type ModalConfigEventPayload = {
	type: EModalEvent.Config;
	payload: IModalConfig;
};

export type ModalEvents =
	| OpenModalEventPayload
	| CloseModalEventPayload
	| ModalConfigEventPayload
	| ModalAlertConfigEventPayload;
