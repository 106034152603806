import { Subject } from 'rxjs';

import { IModalConfig, ModalEvents } from './types';

export const modalController$ = new Subject<ModalEvents>();

export const MODAL_CONFIG: IModalConfig = {
	blockAlert: false,
	showOverlay: false,
	showCloseButton: true
};
